<template>
  <div>
    <ValidationObserver ref="userValidation">
      <BRow class="d-flex align-items-start">
        <BCol cols="3">
          <BCard>
            <BCol
              class="d-flex justify-content-between flex-column"
              cols="21"
            >
              <div class="d-flex flex-column align-items-center justify-content-start">
                <template>
                  <BAvatar
                    :src="dataAvatar"
                    rounded
                    size="250px"
                  />
                </template>
                <div class="d-flex flex-column ml-1">
                  <div class="d-flex mt-2 flex-wrap">
                    <BFormFile
                      v-model="formData.profile_picture"
                      drop-placeholder="Перетащите файл сюда"
                      placeholder="Выберите файл"
                      browse-text="Выбрать"
                      @input="fileInput"
                    />
                    <BButton
                      class="ml-auto mt-1"
                      variant="danger"
                      @click="deletePhoto"
                    >
                      <feather-icon icon="TrashIcon" />
                      Удалить
                    </BButton>
                  </div>
                </div>
              </div>
            </BCol>
          </BCard>
        </BCol>
        <BCol cols="6">
          <BCard>
            <BRow>
              <BCol cols="4">
                <BFormGroup label="Имя пользователя">
                  <ValidationProvider
                    #default="{ errors }"
                    name="Имя пользователя"
                    rules="min:4"
                  >
                    <BFormInput
                      v-model="formData.username"
                      placeholder="Имя пользователя"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </BFormGroup>
              </BCol>

              <BCol cols="4">
                <BFormGroup label="Пароль">
                  <ValidationProvider
                    #default="{ errors }"
                    name="Пароль"
                    rules="min:4"
                  >
                    <!--                    rules="digits:4"-->
                    <BInputGroup>
                      <BFormInput
                        v-model="formData.password"
                        placeholder="Пароль"
                        :type="passwordToggleType(hidePswd)"
                        :disabled="Boolean($route.params.id)"
                      />
                      <BInputGroupAppend
                        v-if="!Boolean($route.params.id)"
                        is-text
                      >
                        <feather-icon
                          :icon="passwordToggleIcon(hidePswd)"
                          class="cursor-pointer"
                          @click="hidePswd = !hidePswd"
                        />
                      </BInputGroupAppend>
                    </BInputGroup>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </BFormGroup>
              </BCol>

              <BCol cols="4">
                <div
                  v-if="Boolean($route.params.id)"
                  class="d-flex align-items-end h-100"
                >
                  <BButton
                    class="mb-1"
                    @click="openModal"
                  >
                    Изменить пароль
                  </BButton>
                </div>

                <BFormGroup
                  v-else
                  label="Подтвердите пароль"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="Подтвердите пароль"
                    rules="required|confirm:@Пароль"
                  >
                    <BInputGroup>
                      <BFormInput
                        v-model="formData.confirm_password"
                        :type="passwordToggleType(hideConfirm)"
                        placeholder="Подтвердите пароль"
                      />

                      <BInputGroupAppend is-text>
                        <feather-icon
                          :icon="passwordToggleIcon(hideConfirm)"
                          class="cursor-pointer"
                          @click="hideConfirm = !hideConfirm"
                        />
                      </BInputGroupAppend>
                    </BInputGroup>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </BFormGroup>
              </BCol>
            </BRow>

            <BRow>
              <BCol cols="8">
                <BFormGroup label="Роли">
                  <ValidationProvider
                    #default="{ errors }"
                    name="Роли"
                    rules="required"
                  >
                    <VSelect
                      v-model="formData.roles"
                      :options="roles"
                      label="name"
                      multiple
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </BFormGroup>
              </BCol>

              <BCol>
                <BFormGroup label="Статус">
                  <b-form-checkbox
                    v-model="formData.is_active"
                    checked="true"
                    name="check-button"
                    switch
                    inline
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </BFormGroup>
              </BCol>
            </BRow>
          </BCard>
        </BCol>

        <BCol cols="3">
          <BCard>
            <BFormGroup label="Имя">
              <ValidationProvider
                #default="{ errors }"
                name="Имя"
                rules="required"
              >
                <BFormInput
                  v-model="formData.first_name"
                  placeholder="Имя"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
            <BFormGroup label="Фамилия">
              <BFormInput
                v-model="formData.last_name"
                placeholder="Фамилия"
              />
            </BFormGroup>
            <BFormGroup label="E-mail">
              <BFormInput
                v-model="formData.email"
                placeholder="Адрес электронной почты"
              />

            </BFormGroup>

            <BFormGroup label="Номер телефона">
              <cleave
                ref="cleave"
                v-model="formData.phone_number"
                :options="mask_options.phone_number"
                class="form-control"
                placeholder=""
                tabindex="5"
              />
            </BFormGroup>
          </BCard>
        </BCol>
      </BRow>

      <div class="d-flex justify-content-end">
        <BButton
          class="mr-1"
          @click="$router.push({ name: 'users-home' })"
        >
          Отменить
        </BButton>
        <BButton
          variant="primary"
          @click="submitHandle"
        >
          Сохранить
        </BButton>
      </div>
    </ValidationObserver>
    <TheChangePasswordModalVue
      :id="$route.params.id"
      ref="changePassword"
      @input="patchPassword"
    />
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import VSelect from 'vue-select'
import { extend } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import TheChangePasswordModalVue from '../components/TheChangePasswordModal.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.uz'

extend('confirm', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Пароли не совпадают',
})

export default {
  name: 'AddEdit',
  components: {
    BAvatar,
    BButton,
    BCard,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormFile,
    BFormCheckbox,
    VSelect,
    TheChangePasswordModalVue,
    Cleave,
  },

  data() {
    return {
      hideConfirm: true,
      hidePswd: true,
      dataAvatar: '',
      formData: {
        profile_picture: '',
        username: '',
        password: '',
        confirm_password: '',
        roles: [],
        is_active: true,
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
      },
      mask_options: {
        phone_number: {
          // phone: true,
          phoneRegionCode: 'RU',
          delimiter: '-',
          // prefix: '',
          // noImmediatePrefix: true,
          // signBeforePrefix: true,
          prefix: '+7',
          blocks: [2, 3, 3, 2, 2],
          uppercase: true,
        },
      },
    }
  },

  computed: {
    ...mapState('users', ['roles', 'userDetail']),
  },
  mounted() {
    console.clear()
    this.FETCH_ALL_ROLES()
    if (this.$route.params.id) {
      this.FETCH_ONE_USER(this.$route.params.id)
        .then(() => {
          this.formData.password = '*********'
          this.formData = {
            ...this.formData,
            ...this.userDetail,
          }
          // this.dataAvatar = this.fixUrlPath(this.userDetail.profile_picture)
          this.dataAvatar = this.formData.profile_picture
        })
    }
    console.clear()
  },

  methods: {
    ...mapActions('users', [
      'FETCH_ALL_ROLES',
      'CREATE_USER',
      'PATCH_USER',
      'FETCH_ONE_USER',
    ]),
    passwordToggleIcon: state => (state ? 'EyeIcon' : 'EyeOffIcon'),
    passwordToggleType: state => (state ? 'password' : 'text'),

    fileInput(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      // eslint-disable-next-line no-return-assign
      reader.onload = () => (this.dataAvatar = reader.result)
    },

    async submitHandle() {
      const valid = await this.$refs.userValidation.validate()
      if (!valid) return

      const {
        username,
        password,
        confirm_password,
        is_active,
        first_name,
        last_name,
        phone_number,
        roles,
        profile_picture,
        email,
      } = this.formData

      const item = {
        username,
        is_active,
        first_name,
        last_name,
        phone_number,
        roles: roles.map(e => e.id),
        email,
      }

      if (this.$route.params.id) {
        // When editing a user, only specify their id
        item.id = this.$route.params.id
      } else {
        // Set password when creating a user
        item.password = password
        item.confirm_password = confirm_password
      }

      if (profile_picture instanceof File) {
        // If a file for avatar upload is provided, include it in the item object
        item.profile_picture = profile_picture
      }

      try {
        const req = this.$_convertToFormdata(item)
        await (item.id ? this.PATCH_USER(req) : this.CREATE_USER(req))
        await this.$router.push({ name: 'users-home' })
        await this.$_okToast()
      } catch (e) {
        await this.$_errorToast(e)
      }
    },

    patchPassword(taget) {
      const items = {
        id: this.$route.params.id,
        password: taget.password,
        confirm_password: taget.confirm_password,
      }
      const req = this.$_convertToFormdata(items)
      this.PATCH_USER(req)
        .then(this.$_okToast)
        .catch(this.$_errorToast)
    },
    fixUrlPath(url) {
      return process.env.VUE_APP_FILE_URL + url
    },
    openModal() {
      this.$refs.changePassword.open()
    },
    deletePhoto() {
      if (this.$route.params.id) {
        const items = {
          id: this.$route.params.id,
          profile_picture: null,
        }
        const req = this.$_convertToFormdata(items)
        this.PATCH_USER(req)
          .then(() => {
            this.dataAvatar = ''
            this.$_okToast()
          })
          .catch(this.$_errorToast)
      } else {
        this.dataAvatar = null
        this.formData.profile_picture = null
        console.clear()
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}

.w-20 {
  width: 20%;
}
</style>
<!--async submitHandle() {-->
<!--const valid = await this.$refs.userValidation.validate()-->
<!--if (!valid) return-->

<!--const {-->
<!--username,-->
<!--password,-->
<!--confirm_password,-->
<!--is_active,-->
<!--first_name,-->
<!--last_name,-->
<!--phone_number,-->
<!--roles,-->
<!--profile_picture,-->
<!--email,-->
<!--} = this.formData-->
<!--const item = {-->
<!--username,-->
<!--is_active,-->
<!--first_name,-->
<!--last_name,-->
<!--phone_number,-->
<!--roles: roles.map(e => e.id),-->
<!--email,-->
<!--}-->

<!--if (this.$route.params.id) {-->
<!--//  при редактировании пользователя только указываем его id-->
<!--item.id = this.$route.params.id-->
<!--} else {-->
<!--//  указываю пороль при создании пользователя-->
<!--item.password = password-->
<!--item.confirm_password = confirm_password-->
<!--}-->
<!--// eslint-disable-next-line camelcase-->
<!--if (profile_picture instanceof File) {-->
<!--// если пришел файл для загрузки аватара то передаю его в объект item и передаю его в метод для создания или редактирования пользователя-->
<!--// eslint-disable-next-line camelcase-->
<!--item.profile_picture = profile_picture-->
<!--}-->

<!--const req = this.$_convertToFormdata(item);-->

<!--(item.id ? this.PATCH_USER : this.CREATE_USER)(req)-->
<!--.then(() => {-->
<!--this.$router.push({ name: 'users-home' })-->
<!--this.$_okToast()-->
<!--}).catch(e => {-->
<!--this.$_errorToast(e)-->
<!--})-->
<!--},-->
