var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"userValidation"},[_c('BRow',{staticClass:"d-flex align-items-start"},[_c('BCol',{attrs:{"cols":"3"}},[_c('BCard',[_c('BCol',{staticClass:"d-flex justify-content-between flex-column",attrs:{"cols":"21"}},[_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-start"},[[_c('BAvatar',{attrs:{"src":_vm.dataAvatar,"rounded":"","size":"250px"}})],_c('div',{staticClass:"d-flex flex-column ml-1"},[_c('div',{staticClass:"d-flex mt-2 flex-wrap"},[_c('BFormFile',{attrs:{"drop-placeholder":"Перетащите файл сюда","placeholder":"Выберите файл","browse-text":"Выбрать"},on:{"input":_vm.fileInput},model:{value:(_vm.formData.profile_picture),callback:function ($$v) {_vm.$set(_vm.formData, "profile_picture", $$v)},expression:"formData.profile_picture"}}),_c('BButton',{staticClass:"ml-auto mt-1",attrs:{"variant":"danger"},on:{"click":_vm.deletePhoto}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_vm._v(" Удалить ")],1)],1)])],2)])],1)],1),_c('BCol',{attrs:{"cols":"6"}},[_c('BCard',[_c('BRow',[_c('BCol',{attrs:{"cols":"4"}},[_c('BFormGroup',{attrs:{"label":"Имя пользователя"}},[_c('ValidationProvider',{attrs:{"name":"Имя пользователя","rules":"min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BFormInput',{attrs:{"placeholder":"Имя пользователя"},model:{value:(_vm.formData.username),callback:function ($$v) {_vm.$set(_vm.formData, "username", $$v)},expression:"formData.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"cols":"4"}},[_c('BFormGroup',{attrs:{"label":"Пароль"}},[_c('ValidationProvider',{attrs:{"name":"Пароль","rules":"min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BInputGroup',[_c('BFormInput',{attrs:{"placeholder":"Пароль","type":_vm.passwordToggleType(_vm.hidePswd),"disabled":Boolean(_vm.$route.params.id)},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),(!Boolean(_vm.$route.params.id))?_c('BInputGroupAppend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon(_vm.hidePswd)},on:{"click":function($event){_vm.hidePswd = !_vm.hidePswd}}})],1):_vm._e()],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"cols":"4"}},[(Boolean(_vm.$route.params.id))?_c('div',{staticClass:"d-flex align-items-end h-100"},[_c('BButton',{staticClass:"mb-1",on:{"click":_vm.openModal}},[_vm._v(" Изменить пароль ")])],1):_c('BFormGroup',{attrs:{"label":"Подтвердите пароль"}},[_c('ValidationProvider',{attrs:{"name":"Подтвердите пароль","rules":"required|confirm:@Пароль"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BInputGroup',[_c('BFormInput',{attrs:{"type":_vm.passwordToggleType(_vm.hideConfirm),"placeholder":"Подтвердите пароль"},model:{value:(_vm.formData.confirm_password),callback:function ($$v) {_vm.$set(_vm.formData, "confirm_password", $$v)},expression:"formData.confirm_password"}}),_c('BInputGroupAppend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon(_vm.hideConfirm)},on:{"click":function($event){_vm.hideConfirm = !_vm.hideConfirm}}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('BRow',[_c('BCol',{attrs:{"cols":"8"}},[_c('BFormGroup',{attrs:{"label":"Роли"}},[_c('ValidationProvider',{attrs:{"name":"Роли","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VSelect',{attrs:{"options":_vm.roles,"label":"name","multiple":""},model:{value:(_vm.formData.roles),callback:function ($$v) {_vm.$set(_vm.formData, "roles", $$v)},expression:"formData.roles"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',[_c('BFormGroup',{attrs:{"label":"Статус"}},[_c('b-form-checkbox',{attrs:{"checked":"true","name":"check-button","switch":"","inline":""},model:{value:(_vm.formData.is_active),callback:function ($$v) {_vm.$set(_vm.formData, "is_active", $$v)},expression:"formData.is_active"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)])],1)],1)],1)],1)],1),_c('BCol',{attrs:{"cols":"3"}},[_c('BCard',[_c('BFormGroup',{attrs:{"label":"Имя"}},[_c('ValidationProvider',{attrs:{"name":"Имя","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BFormInput',{attrs:{"placeholder":"Имя"},model:{value:(_vm.formData.first_name),callback:function ($$v) {_vm.$set(_vm.formData, "first_name", $$v)},expression:"formData.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('BFormGroup',{attrs:{"label":"Фамилия"}},[_c('BFormInput',{attrs:{"placeholder":"Фамилия"},model:{value:(_vm.formData.last_name),callback:function ($$v) {_vm.$set(_vm.formData, "last_name", $$v)},expression:"formData.last_name"}})],1),_c('BFormGroup',{attrs:{"label":"E-mail"}},[_c('BFormInput',{attrs:{"placeholder":"Адрес электронной почты"},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})],1),_c('BFormGroup',{attrs:{"label":"Номер телефона"}},[_c('cleave',{ref:"cleave",staticClass:"form-control",attrs:{"options":_vm.mask_options.phone_number,"placeholder":"","tabindex":"5"},model:{value:(_vm.formData.phone_number),callback:function ($$v) {_vm.$set(_vm.formData, "phone_number", $$v)},expression:"formData.phone_number"}})],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('BButton',{staticClass:"mr-1",on:{"click":function($event){return _vm.$router.push({ name: 'users-home' })}}},[_vm._v(" Отменить ")]),_c('BButton',{attrs:{"variant":"primary"},on:{"click":_vm.submitHandle}},[_vm._v(" Сохранить ")])],1)],1),_c('TheChangePasswordModalVue',{ref:"changePassword",attrs:{"id":_vm.$route.params.id},on:{"input":_vm.patchPassword}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }