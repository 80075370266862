<template>
  <BModal
    v-model="modalState"
    title="Изменить пароль"
    centered
    ok-title="Подтвердить"
    cancel-title="Отменить"
    @ok.prevent="submitHandle"
  >
    <ValidationObserver ref="newPasswordValidation">
      <BFormGroup label="Подтвердить пароль">
        <ValidationProvider
          #default="{ errors }"
          name="Пароль"
          rules="required"
        >
          <BInputGroup :class="errors.length > 0 ? 'is-invalid' : null">
            <BFormInput
              v-model="formData.password"
              :type="passwordToggleType(hidePswd)"
              :state="errors.length > 0 ? false : null"
            />
            <BInputGroupAppend is-text>
              <feather-icon
                :icon="passwordToggleIcon(hidePswd)"
                class="cursor-pointer"
                @click="hidePswd = !hidePswd"
              />
            </BInputGroupAppend>
          </BInputGroup>
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </BFormGroup>
      <BFormGroup label="Подтвердить пароль">
        <ValidationProvider
          #default="{ errors }"
          name="Подтвердите пароль"
          rules="required|confirm:@Пароль"
        >
          <BInputGroup :class="errors.length > 0 ? 'is-invalid' : null">
            <BFormInput
              v-model="formData.confirm_password"
              :type="passwordToggleType(hideConfirm)"
              :state="errors.length > 0 ? false : null"
            />
            <BInputGroupAppend is-text>
              <feather-icon
                :icon="passwordToggleIcon(hideConfirm)"
                class="cursor-pointer"
                @click="hideConfirm = !hideConfirm"
              />
            </BInputGroupAppend>
          </BInputGroup>
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </BFormGroup>
    </ValidationObserver>
  </BModal>
</template>

<script>
import { min, required, email } from '@validations'
import { extend } from 'vee-validate'
import {
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from 'bootstrap-vue'

extend('confirm', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Пароли не совпадают',
})

export default {
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  props: {
    id: {
      type: [Number, String],
    },
  },
  data() {
    return {
      modalState: false,
      hidePswd: true,
      hideConfirm: true,
      formData: {},
    }
  },
  methods: {
    async submitHandle() {
      const valid = await this.$refs.newPasswordValidation.validate()
      if (!valid) return
      this.$emit('input', this.formData)
      this.modalState = false
    },
    open() {
      this.modalState = true
    },
    passwordToggleIcon: state => (state ? 'EyeIcon' : 'EyeOffIcon'),
    passwordToggleType: state => (state ? 'password' : 'text'),
  },
}
</script>

<style>
</style>
